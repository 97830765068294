'use client';

import Providers from '@/components/providers';
import { Theme, themePropDefs } from '@radix-ui/themes';
import { getCookie } from 'cookies-next';

export enum Themes {
    Light = 'light',
    Dark = 'dark',
    Inherit = 'inherit'
}

export function ThemeControl({ children, session }: { children: React.ReactNode, session: any }) {
  const theme = getCookie('theme');
  return (
      <Theme appearance={theme ? Themes[theme as keyof typeof Themes] : undefined}>
        {children}
      </Theme>
  )
}

export const getTheme = (): Themes => {
    let curr = document.documentElement.getAttribute('class');
    return curr ? Themes[curr as keyof typeof Themes] : Themes.Inherit;
}